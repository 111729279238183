.title {
  font-family: $titoletti;
  color: black;
  margin-top: 0;
  font-weight: normal;
  @media #{$large-mobile} {
  font-size: 2rem;
  line-height: 2.2rem;
   }
  &--white {
    color: white;
    font-family: $titoletti-white;
  }
}

.title-newsletter {
  font-family: $titoletti;
  color: white;
  margin: 0rem 0rem 2.5rem 0rem;
  font-weight: normal;
  font-size: 2rem;
  @media #{$large-mobile} {
  font-size: 1.7rem;
  line-height: 2rem;
   }
}


.title-hero {
  font-family: $titoletti;
  color: white;
  margin-top: 0;
  font-weight: normal;
  font-size: 5rem;
  @media #{$large-mobile} {
  font-size: 3rem;
  line-height: 3.5rem;
   }
}

.title-about {
  font-family: $titoletti;
  color: black;
  margin-top: 0;
  font-weight: normal;
  font-size: 7rem;
  letter-spacing: 0;
  @media #{$large-mobile} {
  font-size: 4rem;
  line-height: 3.75rem;
   }
}

.subtitle-about {
  font-family: $titoletti;
  color: #1c42fd;
  margin-top: 0;
  font-weight: normal;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 2.5rem;
  @media #{$large-mobile} {
  font-size: 2rem;
  line-height: 2.3rem;
   }
}




.title-article {
  font-family: $titoletti;
  color: black;
  margin-top: 0;
  font-weight: normal;
  font-size: 4rem;
  line-height: 4.5rem;
  @media #{$large-mobile} {
  font-size: 3rem;
  line-height: 3.5rem;
   }
}


.title-black {
  font-family: $titoletti;
  color: black;
  margin-top: 0;
  font-weight: normal;
  font-size: 2.75rem;
  margin-bottom: 0.7rem;
  @media #{$desktop} {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 0.4rem;
   }
  @media #{$large-mobile} {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 0rem;
   }
  &--white {
    color: white;
    font-family: $titoletti-white;
  }
}

.title-black_articles {
  font-family: $titoletti;
  color: black;
  margin-top: 0;
  font-weight: normal;
  font-size: 2.2rem;
  margin: 0.75rem 0 0.75rem 0;
  @media #{$small-desktop} {
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 1rem 0 2rem 0;
   }
  @media #{$large-mobile} {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 1rem 0 2rem 0;
   }
  &--white {
    color: white;
    font-family: $titoletti-white;
  }
}

.subtitle-article{
  font-family: $testo-medium-sans-light;
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #1735fc;
    @media #{$large-mobile} {
    font-size: 1.8rem;
    line-height: 2.4rem;
      width:100%;
     }
}

.div-title-artile{
  width:75%;
  @media #{$large-mobile} {
    width:100%;
   }
}


.subtitle-text-article{
  font-family: $testo-medium-sans-bold;
  line-height: 2rem;
  font-size: 1rem;
  @media #{$large-mobile} {
    line-height: 2rem;
    font-size: 1rem;
   }
}


.subtitle-text-article-blu{
  color: #1c42fd;
}

.text-home{
  color:#999999;
  font-family: $testo;
  font-size: 0.9rem;
  line-height: 1.4rem;
  width: 70%;
  @media #{$large-mobile} {
      width: 100%;
   }
}

.text-newsletter{
  color: white;
  font-family: $testo;
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin-bottom: 2rem;
}

.text-hero {
  font-family: $testo;
  color: white;
  margin-top: 0;
  font-weight: normal;
  line-height: 1.65rem;
  @media #{$large-mobile} {
    max-width: 80%;
   }
}

.button-text-header {
  font-family: $titoletti;
  color: black;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  @media #{$large-mobile} {
  font-size: 1.2rem;
   }
}

.button-menu-header {
  @media #{$large-mobile} {
    font-family: $testo-medium-sans-regular;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
   }
}

.title-preview{
    font-family: $titoletti;
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin: 0.5rem 0 0 0;
    @media #{$large-mobile} {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0.5rem 0 2rem 0;
     }
}

.title-footer{
  width: 18vw;
  margin: 0rem 1rem 0rem 0rem;
  font-family: $testo-medium-sans-regular;
  border-top: 1.5px solid black;
  padding: 1rem 0rem 1rem 0rem;
  font-size: 0.85rem;
  @media #{$large-mobile} {
        width: 100%;
    }
}

.title-footer-2{
  width: 18vw;
  margin: 0rem 1rem 0rem 0rem;
  font-family: $testo-medium-sans-regular;
  border-bottom: 1.5px solid black;
  padding: 1rem 0rem 1rem 0rem;
  font-size: 0.85rem;
  @media #{$large-mobile} {
        width: 100%;
    }
}

.title-footer-3{
  width: 18vw;
  margin: 0rem 1rem 0rem 0rem;
  font-family: $testo-medium-sans-regular;
  border-top: 1.5px solid black;
  border-bottom: 1.5px solid black;
  padding: 1rem 0rem 1rem 0rem;
  font-size: 0.85rem;
  cursor: pointer;
  @media #{$large-mobile} {
        width: 100%;
    }
}

.title-footer:hover{
    font-family: $testo-medium-sans;
      cursor: pointer;
}

.title-footer-2:hover{
    font-family: $testo-medium-sans;
      cursor: pointer;
}


.title-footer-3:hover{
    font-family: $testo-medium-sans;
      cursor: pointer;
}

.text-footer{
    font-family: $testo-medium-sans;
    font-size: 0.7rem;
    line-height: 1.1rem;
    margin:4rem 0rem 0rem 0rem;
}


.text-card-about{
   font-family: $titoli;
   // font-weight: normal;
}

.text-about-bold{
   font-family: $testo-medium-sans-bold;
   line-height: 1.4rem;
   @media #{$large-mobile} {
     }
}

.text-about-regular{
   font-family: $testo-medium-sans-regular;
   line-height: 1.4rem;
   @media #{$large-mobile} {
     }
}

.text-article-bold{
     font-family: $testo-medium;
}

.autore_H3{
    font-family: $titoletti;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin: 0;
    @media #{$large-mobile} {
      font-size: 1.5rem;
      line-height: 2.2rem;
      }
}

.bio{
  margin: 0;
  font-family: $testo-medium-sans-regular;
  @media #{$large-mobile} {
      margin-top: 2.5rem;
    }
}

.vedi_di_più{
  font-size: 1rem;
  color:#999999;
  font-family: $testo-medium-sans-regular;
  font-weight: normal;
  margin-right: 0.5rem;
}

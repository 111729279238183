$titoli: SM_book;
$titoli-2: SM_book;
$titoli-3: SM_light;
$titoletti: SM_book;
$titoletti-white: SM_book;
$testo: IBM_text;
$testo-medium: IBM_medium;
$testo-medium-sans-bold: IBM_Sans_bold;
$testo-medium-sans: IBM_Sans_medium;
$testo-medium-sans-regular: IBM_Sans_regular;
$testo-medium-sans-light: IBM_Sans_light;
$corner-1: 0.5rem;
$corner-2: 0.3rem;
$purple: #4d46f5;

@font-face {
    font-family: 'SM_Book';
    src: url('../type/SMHauser-60.woff2') format('woff2'),
         url('../type/SMHauser-60.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SM_light';
    src: url('../type/SMHauser-35.woff2') format('woff2'),
         url('../type/SMHauser-35.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM_text';
    src: url('../type/IBMPlexSerif-Text.woff2') format('woff2'),
         url('../type/IBMPlexSerif-Text.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'IBM_Sans_medium';
    src: url('../type/IBMPlexSans-Medium.woff2') format('woff2'),
         url('../type/IBMPlexSans-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM_Sans_regular';
    src: url('../type/IBMPlexSans-Regular.woff2') format('woff2'),
         url('../type/IBMPlexSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM_Sans_light';
    src: url('../type/IBMPlexSans-Light.woff2') format('woff2'),
         url('../type/IBMPlexSans-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM_Sans_bold';
    src: url('../type/IBMPlexSans-Bold.woff2') format('woff2'),
         url('../type/IBMPlexSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$rose: #f4a992;
$red-accent: #ff2c2c;
$red: #7f140e;
$dark-red: #4c0c14;
$animation: all 1s ease-out;
$animation-2: all 0.15s ease-out;
$border-radius: 0.4rem;
$shadow: 0 0 0 5px #19F;
$shadow-2: 0 0 10px grey;

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus{
   outline: 0;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  width: auto;
  // transition: $animation;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
}

footer {
  background-color: #d4d4d4;
  color: black !important;
  font-family: medium;
  padding: 2rem 2rem 2.5rem 6rem;
  @media #{$large-mobile} {
        padding-bottom: 2.5rem;
    }
}

.section-footer {
  margin: 0 auto;
  max-width: 1440px;
  padding:3rem 6rem 3rem 6rem;
  transition: $animation;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding:3rem 2rem 3rem 2rem;
  }

}

a {
  text-decoration: none;
  &--purple{
    color: $purple;
  }
}


img {
  border-radius: 1.5rem;
}

video {
  border-radius: 1.5rem;
}

h2{
  font-family: medium;
  font-size: 1.7rem;
  line-height: 1.9rem;
  margin: 0;
  letter-spacing: 0.1rem;
  @media #{$large-mobile} {
        padding-bottom: 1.45rem;
        line-height: 1.7rem;
    }
}

.medium-zoom-overlay {
  background: rgba(0,0,0,0.8) !important;
  backdrop-filter: saturate(180%) blur(20px);
  z-index:9999;
  transition: opacity .3s !important;
}

.medium-zoom-image {
    &--opened {
      z-index:9999;
      border-radius: 1rem;

    }
}

.header-subject{

}

.header-subject:hover{
  // padding-right: 4rem;
  // transition: .2s;
}

.previus-next{
  font-family: regular;
  color:grey;
}

.previus-next:hover{
  color:$purple;
}

.subtitle-grey{
  font-family: medium;
  color: grey;
}

.a-fade {
  color: white;
  opacity: 100%;
}

.a-fade-black {
  color: black;
  opacity: 100%;
}

.a-fade-black-project {
  color: black;
  p{
    opacity: 100%;
  }
}

.a-fade-black-project:hover {
  transition: 0.6s;
  p{
    opacity: 60%;
  }
}

.a-fade:hover {
  opacity: 60%;
  transition: 0.6s;
}

.a-fade-black:hover {
  opacity: 60%;
  transition: 0.6s;
}

.img-colored {
  // filter: contrast(0.5) grayscale(100%) sepia(100%) hue-rotate(30deg);
}

.hide{
  opacity: 0%;
}

.voci-anima {
}

.voci-anima:hover{
  opacity: 75%;
}

.no-break{
  white-space: nowrap;
}

.header-div{
  z-index: 999999;
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 0.5rem 6rem 3rem 6rem;
  background: white;
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.16);
  background: rgba(255,255,255,0.9);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  // background: rgba(255,255,255,0.7);
  // backdrop-filter: saturate(200%) blur(30px);
  // mix-blend-mode: difference;
  // box-shadow: $shadow-2;
  top: 0;
  left: 0;
  right: 0;
  height: var(--xxxxl);
  z-index: 9;
  overflow: visible;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-transition: background-color 1.3s;
  transition: background-color 1.3s;

  :hover {
    // opacity: 75%;
    transition: 0.3s;
  }
  h1{
    font-family: SM_book;
    font-size: 2.5rem;
    margin: 0;
    letter-spacing: 0.1rem;
    font-weight: normal !important;
  }
  @media #{$large-mobile} {
        padding: 0.5rem 1rem 2rem 1rem;
  }
}

.footer-div {
  display: flex;
  justify-content: space-between;
    @media #{$large-mobile} {
        display: flex;
        flex-direction: column;
    }
}

.footer-text-big{
  font-size: 2vw;
  margin: 0;
  @media #{$small-desktop} {
      font-size: 1vw;
  }
  @media #{$large-mobile} {
    font-size: 25px;
  }
}

.footer-text-big-dimension {
  width: 50%;
  margin-right: 4rem;
  @media #{$large-mobile} {
      margin-right: 0rem;
      width: 100%;
  }
}

.footer-text-small{
  font-size: 1.1vw;
  @media #{$large-mobile} {
    font-size: 18px;
  }
}

h1{
  font-family: medium;
  font-size: 2.5rem;
  margin: 0;
  letter-spacing: 0.1rem;
}

.text-purple {
  color: $purple;
}

.logo{
  transition: $animation-2;
  margin: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 28rem;
  // -webkit-backdrop-filter: saturate(180%) blur(20px);
  // backdrop-filter: saturate(10%) blur(40px);
  // background-blend-mode: difference;
  @media #{$small-desktop} {
      width: 24rem;
  }
  @media #{$large-mobile} {
      width: 16rem;
  }
}

.logo:hover{
  opacity: 55%;
  transition: $animation-2;
}


.logo-footer{
  transition: $animation-2;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 20rem;
  fill: white;
  // -webkit-backdrop-filter: saturate(180%) blur(20px);
  // backdrop-filter: saturate(10%) blur(40px);
  // background-blend-mode: difference;
  filter: none !important;
  @media #{$large-mobile} {
      width: 15rem;
  }
}

.logo-footer-2{
  transition: $animation-2;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 3rem;
  width: 6rem;
  // -webkit-backdrop-filter: saturate(180%) blur(20px);
  // backdrop-filter: saturate(10%) blur(40px);
  // background-blend-mode: difference;
  filter: none !important;
  @media #{$large-mobile} {
      width: 4rem;
  }
}


.logo-footer:hover{
  opacity: 55%;
  transition: $animation-2;
}


.arrow{
  height:1.75rem;
  margin:0;
  padding:0;
  @media #{$large-mobile} {
        height:1rem;
  }
}

a {
  color: black;
}

::selection {
  color: white;
  background: $purple;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-end {
  display: flex;
  justify-content: flex-end;
  @media #{$large-mobile} {
    justify-content: center;
  }
}


.justify-content-space {
  display: flex;
  justify-content: space-between;
  @media #{$large-mobile} {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.flex_column {
  flex-direction: column;
}

.header{
  z-index: 9999;
  position: absolute;
  left: 2rem;
}

.main-wrapper{
  position: relative;
  overflow: hidden;
}


.hero-div-content{
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  @media #{$large-mobile} {
    flex-direction: column;
  }
}

.hero-div-text{
  max-width: 50%;
  @media #{$large-mobile} {
      max-width: 100%;
  }
}


.section-1{
  margin: 0 auto;
  max-width: 1440px;
  padding:3rem 6rem 3rem 6rem;
  transition: $animation;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding:3rem 2rem 3rem 2rem;
  }
}

.section-articles{
  margin: 0 auto;
  height: fit-content;
  max-width: 1440px;
  transition: $animation;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding:3rem 2rem 3rem 2rem;
    margin-bottom: 1.5rem;
  }
}


.section-filters{
  margin: 0 auto;
  max-width: 1440px;
  padding:6rem 0rem 3rem 0rem;
  transition: $animation;
  display: flex;
  text-align: center;
  @media #{$large-mobile} {
    padding:6rem 0rem 2rem 0rem;
  }
}

.div-filters{
  display: flex;
  @media #{$small-desktop} {
    flex-direction: column;
  }
  @media #{$large-mobile} {
    flex-direction: column;
  }
}

.section-newletter{
  margin: 0 auto;
  max-width: 1440px;
  padding:3rem 6rem 3rem 6rem;
  transition: $animation;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding:3rem 2rem 3rem 2rem;
  }
}

.newsletter{
  // background-image: url("../images/newsletter_bg.svg");
  background-color: #011dff;
  color: white;
  border-radius: 2rem;
  padding: 4rem 8rem 4rem 8rem;
  margin-bottom: 2rem;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media #{$large-mobile} {
     padding: 4rem 3rem 4rem 3rem;
     max-width: 100%;
  }
}

.section-article{
  margin: 0 auto;
  max-width: 1440px;
  padding:8rem 6rem 8rem 6rem;
  transition: $animation;
  font-family: $testo;
  line-height: 1.6rem;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding:10rem 1rem 6rem 1rem;
  }
}

.section-scopri-articoli-btn{
  margin: 0 auto;
  max-width: 1440px;
  padding:5rem 6rem 3rem 5rem;
  transition: $animation;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding:3rem 2rem 3rem 2rem;
  }
}


.text-article{
  max-width: 50%;
  @media #{$large-mobile} {
     max-width: 100%;
  }
}

.text-article-large{
  max-width: 75%;
  @media #{$large-mobile} {
     max-width: 100%;
  }
}

.text-article-blue{
 color: #1c42fd;
}

.section-2{
  margin: 0 auto;
  max-width: 1440px;
}

.img-home{
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-div{
  background-color: #001eff;
  height: 60vh;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  margin-top: 4rem;
  padding: 2rem 6rem 2.5rem 6rem;
  display: flex;
  @media #{$desktop} {
    height: 60vh;
  }
  @media #{$large-mobile} {
    height: auto;
    padding: 3.5rem 2rem 2.5rem 2rem;
  }
}

.inside{
    position: absolute;
    top: 0;
    left: 0;
		width: 100%;
		height: 25vw;
		background: #001eff;
		background: -moz-linear-gradient(top,  #001eff 0%, #001eff 50%, #001eff 51%, #001eff 100%);
		background: -webkit-linear-gradient(top,  #001eff 0%,#001eff 50%,#001eff 51%,#001eff 100%);
		background: linear-gradient(to bottom,  #001eff 0%,#001eff 50%,#001eff 51%,#001eff 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001eff', endColorstr='#001eff',GradientType=0 );
	}

.about-div{
  height: auto !important;
  margin: 0 auto;
  margin-top: 4rem;
  width: 100%;
  max-width: 1440px;
  position: relative;
  padding: 2rem 6rem 0rem 6rem;
  display: flex;
  @media #{$desktop} {
    height: 50vh;
  }
  @media #{$large-mobile} {
    height: fit-content !important;
    padding: 3.5rem 2rem 2.5rem 2rem;
    flex-direction: column;
  }
}

.about-div-card{
  overflow: hidden;
  position: relative;
  padding: 2rem 0rem 0rem 6rem;
  display: flex;
  align-items: center;
  @media #{$large-mobile} {
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem 1rem 2rem;
  }
}

.about-div-text{
  overflow: hidden;
  position: relative;
  margin-top: 4rem;
  padding: 2rem 0rem 0rem 6rem;
  display: flex;
  @media #{$large-mobile} {
    margin-top: 0rem;
    padding: 3.5rem 2rem 2.5rem 2rem;
  }
}

.about-div-img{
  position: relative;
  margin-top: 4rem;
  padding: 2rem 6rem 0rem 6rem;
  display: flex;
  @media #{$large-mobile} {
    padding: 2rem 2rem 2rem 2rem;
  }
}


.img-project-div{
  height: auto;
  margin-bottom: 4vw;
  margin-top: 0;
  overflow: hidden;
  position: relative;
  margin: 6rem 1rem 1rem 1rem;
  border-radius: $corner-2;
  @media #{$large-mobile} {
    height: auto;
    margin: 5rem 1rem 1rem 1rem;
  }
}

.img-prjct-1 {
  padding:0rem 0.5rem 1rem 1rem;
  @media #{$large-mobile} {
      padding:0rem 1rem 0.5rem 1rem;
  }
}

.img-prjct-2{
  padding:0rem 1rem 1rem 0.5rem;
  @media #{$large-mobile} {
      padding:0rem 1rem 0.5rem 1rem;
  }
}

.paragraph-righted{
  padding-left: 60vw;
  @media #{$large-mobile} {
     padding-left: 0;
  }
}

.padding-row{
  padding: 0 2rem 0 2rem;
}

.padding-row-1{
  padding: 0 1rem 0 1rem;
}

.paragraph-div{
  padding-left: 1rem;
  padding-right: 2rem;
}

.paragraph-div-index{
  margin: 1.5rem 0 3.5rem 0;
  padding: 0 1rem 0 2rem;
  @media #{$large-mobile} {
      margin: 1rem 0 2rem 0;
  }
}

.paragraph-div-about{
  margin: 5rem 0 3.5rem 0;
  padding: 0 1rem 0 2rem;
  @media #{$large-mobile} {
      margin: 2rem 0 2rem 0;
  }
}

.paragraph-div-footer{
  @media #{$large-mobile} {
    margin-bottom: 1rem;
  }
}

.paragraph-home{
  font-family: medium;
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 0;
  margin-bottom: 1rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 3.3rem;
    line-height: 3.45rem;
  }
  @media #{$desktop} {
    font-size: 3rem;
    line-height: 3.2rem;
  }
  @media #{$small-desktop} {
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.05rem;
  }
  @media #{$large-mobile} {
    font-size: 1.65rem;
    line-height: 1.85rem;
    margin-bottom: 0rem;
  }
}

.paragraph-project{
  font-family: medium;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  margin-bottom: 1rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  @media #{$desktop} {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  @media #{$small-desktop} {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  @media #{$large-mobile} {
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin-bottom: 0rem;
  }
}

.paragraph-about{
  font-family: medium;
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 0;
  margin-bottom: 1rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 3.3rem;
    line-height: 3.45rem;
  }
  @media #{$desktop} {
    font-size: 3rem;
    line-height: 3.2rem;
  }
  @media #{$small-desktop} {
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.05rem;
  }
  @media #{$large-mobile} {
    font-size: 1.65rem;
    line-height: 1.85rem;
    margin-bottom: 0rem;
  }
}

.paragraph-about-regular{
  font-family: regular;
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 0;
  margin-bottom: 1rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 3.3rem;
    line-height: 3.45rem;
  }
  @media #{$desktop} {
    font-size: 3rem;
    line-height: 3.2rem;
  }
  @media #{$small-desktop} {
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.05rem;
  }
  @media #{$large-mobile} {
    font-size: 1.65rem;
    line-height: 1.85rem;
    margin-bottom: 0rem;
  }
}

.fixed-desktop{
  position: fixed;
  @media #{$large-mobile} {
      position: inherit;
      margin-top: 5rem;
  }
}

.small-text-project{
  font-family: medium;
  font-size: 1rem;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 1rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 1rem;
    line-height: 1rem;
  }
  @media #{$desktop} {
    font-size: 1rem;
    line-height: 1rem;
  }
  @media #{$small-desktop} {
    font-size: 1rem;
    line-height: 1rem;
  }
  @media #{$large-mobile} {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0rem;
  }
}

.paragraph-home-3{
  font-family: medium;
  font-size: 1.3rem;
  line-height: 1rem;
  margin: 0;
  margin-bottom: 1rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 1.3rem;
    line-height: 1rem;
  }
  @media #{$desktop} {
    font-size: 1.3rem;
    line-height: 1rem;
  }
  @media #{$small-desktop} {
    font-size: 1.3rem;
    line-height: 1rem;
  }
  @media #{$large-mobile} {
    font-size: 1rem;
    line-height: 0.5rem;
    margin-bottom: 0rem;
  }
}

.paragraph-home-2{
  max-width: 40rem;
  font-family: medium;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-left: 3rem;
  transition: $animation;
  @media #{$large-tablet} {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  @media #{$desktop} {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  @media #{$small-desktop} {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
  @media #{$large-mobile} {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 0rem;
    max-width: 90%;
  }
}

.foto-section{
  padding: 2rem;
  @media #{$large-mobile} {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}


.img-landing-1{
  width:65%;
  border-radius: 0.4rem;
  margin-bottom: 5rem;
  transition: $animation-2;
  @media #{$small-desktop} {
    width:70%;
  }
  @media #{$large-mobile} {
    width:90%;
    margin-bottom: 1.5rem;
  }
}

.video-landing-1{
  width:65%;
  border-radius: 0.4rem;
  margin-bottom: 5rem;
  transition: $animation-2;
  @media #{$small-desktop} {
    width:70%;
  }
  @media #{$large-mobile} {
    width:90%;
    margin-bottom: 1.5rem;
  }
}

// .img-hover:hover{
//   transform: scale(1.05);
//   transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
// }


.img-landing-2{
  width:40%;
  border-radius: 0.4rem;
  margin-bottom: 5rem;
  transition: $animation-2;
  @media #{$small-desktop} {
      width:45%;
  }
  @media #{$large-mobile} {
    width:90%;
    margin-bottom: 1.5rem;
  }
}

.img-landing-3{
  width: 30%;
  border-radius: 0.4rem;
  margin-bottom: 5rem;
  transition: $animation-2;
  @media #{$small-desktop} {
      width: 30%;
  }
  @media #{$large-mobile} {
    width:90%;
    margin-bottom: 1.5rem;
  }
}

.div-colored-red {
  background-color: red;
}

.caption-foto {
  opacity: 0%;
  position: absolute;
  bottom: 2rem;
  z-index: 9999;
}

.caption-foto:hover {
  opacity: 100%;
}

.paragraph-footer{
  max-width: 50rem;
}

.button {
  font-family: $titoletti;
  border-radius: 10rem;
  border: 2px solid #000000;
  padding: 0rem 1.5rem 0rem 1.5rem;
  cursor: pointer;
  width: fit-content;
  @media #{$small-desktop} {
      padding: 0rem 1rem 0rem 1rem;
  }
  @media #{$large-mobile} {
      padding: 0rem 0.5rem 0rem 0.5rem;
  }
}

.button:hover{
  background-color: black;
  h2 {
      color: white;
  }
}

.button-2 {
  font-family: $titoletti;
  border-radius: 10rem;
  border: 2px solid #001eff;
  padding: 0rem 1.5rem 0rem 1.5rem;
  background-color: #001eff;
  color: white;
  cursor: pointer;
  width: fit-content;
  @media #{$large-mobile} {
      padding: 0rem 0.5rem 0rem 0.5rem;
  }
}

.button-2:hover{
  background-color: black;
  border: 2px solid black;
  h2 {
      color: white;
  }
}


.button-blue {
  font-family: $titoletti;
  color: white;
  background-color: #011dff;
  border-radius: 10rem;
  padding: 0rem 1.5rem 0rem 1.5rem;
  width: fit-content;
  font-size: 0.8rem;
}

.button-white {
  font-family: $titoletti;
  color: black;
  background-color: white;
  border-radius: 10rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  width: fit-content;
}

.blu-line {
  width:3.5rem;
  border-top: 2.5px solid #1340fb;
  margin: 0rem 0 2.5rem 0;
}

.tag {
  font-family: $testo-medium-sans;
  color: #011dff;
  background-color: white;
  border-radius: 10rem;
  font-size: 0.9rem;
  padding: 0.33rem 0.8rem 0.33rem 0.8rem;
  border: 1.7px solid #011dff;
  width: fit-content;
  height: fit-content;
  transition: $animation-2;
}

.tag_black {
  font-family: $testo-medium-sans;
  color: black;
  background-color: #c4c4c4;
  border-radius: 10rem;
  font-size: 0.9rem;
  padding: 0.33rem 0.8rem 0.33rem 0.8rem;
  border: 1.7px solid black;
  width: fit-content;
  height: fit-content;
  transition: $animation-2;
}

.tag_black:hover {
  background-color:black;
  color:white;
  cursor: pointer;
}

.tag:hover{
  background-color:#011dff;
  color:white;
  cursor: pointer;
}

.tag_articles {
  font-family: $testo-medium-sans;
  color: #011dff;
  background-color: white;
  border-radius: 10rem;
  font-size: 0.9rem;
  padding: 0.33rem 0.8rem 0.33rem 0.8rem;
  border: 1.7px solid #011dff;
  width: fit-content;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  transition: $animation-2;
}

.tag_articles:hover{
  background-color:#011dff;
  color:white;
  cursor: pointer;
}

.tag_button_articles{
  display: flex;
  @media #{$small-desktop} {
    flex-direction: column;
  }
  @media #{$large-mobile} {
    flex-direction: row;
  }
}

.title-img-article{
  display: flex;
  @media #{$large-mobile} {
    flex-direction: column;
  }
}

.carousel-content-div{
  display: flex;
  justify-content: space-between;
  padding-left: 15rem;
  padding-right: 15rem;
  @media #{$large-mobile} {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}


.carousel-block-2{
  margin-left: 2rem;
  margin-right: 5rem;
  @media #{$large-mobile} {
      margin-left: 1em;
      margin-right: 0rem;
  }
}

.carousel-title{
  font-family: medium;
  margin-top: 0;
}

.carousel-text{
  font-family: medium;
}

.carousel-img{
  height: 28rem;
  border-radius: $border-radius;
}

.button-1 {
  font-family: medium;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  transition: $animation-2;
  cursor: pointer;
  font-size: 1.2rem;
}

.button-1:hover{
    box-shadow: 0 14px 28px grey;
    transition: $animation-2;
     transform: scale(1.02);
     cursor: pointer;
}



.element-100{
   width:100%;
   @media #{$large-mobile} {
      width:100%;
   }
}

.element-50{
   width:50%;
   @media #{$large-mobile} {
      width:100%;
   }
}


.img-articles{
  width:20vw;
  height: fit-content;
  @media #{$large-mobile} {
       width:100%;
       margin: 0 !important;
  }
}

.button-sorting {
  border: 0;
  display: flex;
  background-color: white;
  margin: 2rem;
  font-family: $titoletti;
  font-size: 1.5rem;
  line-height: 2rem;
  border: 2px solid black;
  border-radius: 2rem;
  padding: 0.25rem 2rem 0.25rem 2rem;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: $animation-2;
  @media #{$large-mobile} {
      padding: 0.25rem 1.2rem 0.25rem 1.2rem;
      font-size: 1.3rem;
      margin: 0.65rem;
  }
  svg {
    stroke:black;
    margin-right: 0.65rem;
    stroke-width: 1.5px;
    transition: $animation-2;
  }
}

.button-sorting:hover{
  color:#011dff;
  svg {
    stroke:#011dff;
  }
}

.leggi_articoli{
   margin: 0.5rem 0 0.5rem 0;
}

.grid-1 {
  display: flex;
  flex-direction: row;
  @media #{$large-mobile} {
      flex-direction: column;
  }
}

.grid {
  display: flex;
  @media #{$large-mobile} {
      flex-direction: column;
  }
}



// ----------- drop-menù ----------


// Menu styles
.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  // Menu link styles
  a {
    display: block;
    // padding: 1em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Top-level menu items
  > li {
    display: block;
    position: relative;
  }
}

.c {
        width: 100%;
        height: 100%;
        display: block;
    }


// Sub-menu styles
.sub-menu {

  // These are the key CSS properties to create the effect
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;

  // Typical sub-menu styling
  background-color: white;
  width: 100%;
  box-shadow: 0px 6px 50px 0px rgba(black, 0.3);
  list-style: none;
  margin: 0;
  padding: 1rem;
  position: absolute;
  top: 100%;
  right: 0;
  width: 10em;
  border-radius: $corner-2;;

  li{
    margin: 0.5rem 0rem 0.5rem 0rem;
    opacity: 50%;
    &.top{
          margin: 0.0rem 0rem 0.5rem 0rem !important;
    }
    &.bottom{
          margin: 0.5rem 0rem 0.0rem 0rem !important;
    }
  }
  li:hover {
    opacity: 100% !important;
  }
}

// Sub-menu styles when shown
.menu > li:hover > .sub-menu {
  width: 100%;
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}




.titoletto{
  font-family: $titoli-2;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.3rem;
  padding:0;
  @media #{$large-mobile} {
       font-size: 0.8rem;
   }
}


.hover{
   transition: transform .33s;
   cursor: pointer;
}

.hover:hover {
  transform: scale(1.015);
}


.cursor-pointer{
  cursor: pointer;
}


// .drop-menù {
//   box-shadow: 0px 6px 50px 0px rgb(0 0 0 / 20%);
// }
















// -------------------- Player

/* controls */
.controls, .controls li {
	padding:0;
	margin:0;
}
.controls {
	display:flex !important;
	list-style-type:none;
	overflow:hidden;
	background:transparent;
  margin-top:-4rem;
}
.controls li {
	float:left;
	width:10%;
	margin-left:0.3%;
}
.controls li:first-child {
	margin-left:0;
}
.controls .progress {
	width:38%;
	cursor:pointer;
}
.controls button {
	width:100%;
	text-align:center;
	overflow:hidden;
	white-space:nowrap;
  	text-overflow:ellipsis;
}
.controls progress {
	display:block;
	width:100%;
	height:1.25rem;
	margin-top:0.125rem;
	overflow:hidden;
	-moz-border-radius: $corner-2;
	-webkit-border-radius: $corner-2;
	border-radius: $corner-2;
}
.controls progress span {
	width:0%;
	height:100%;
	display:inline-block;
	background-color: white;
}

.controls progress::-moz-progress-bar {
   background-color: white;
}

.controls progress::-webkit-progress-value {
   background-color: white;
}


/* fullscreen */
html:-ms-fullscreen {
	width:100%;
}
:-webkit-full-screen {
	background-color:transparent;
}
/* hide controls on fullscreen with WebKit */
figure[data-fullscreen=true] video::-webkit-media-controls {
	display:none !important;
}
figure[data-fullscreen=true] {
	max-width:100%;
	width:100%;
	margin:0;
	padding:0;
}
figure[data-fullscreen=true] video {
	height:auto;
}
figure[data-fullscreen=true] figcaption {
	display:none;
}
figure[data-fullscreen=true] .controls {
	position:absolute;
	bottom:2%;
	width:100%;
	z-index:2147483647;
}
figure[data-fullscreen=true] .controls li {
	width:5%;
}
figure[data-fullscreen=true] .controls .progress {
	width:68%;
}

.button-player{
    border: 0;
    background: none;
}









// --------- LAYOUT -------------

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
   user-select: none;
}

.no-wrap{
  white-space: nowrap;
}

.flex {
  display: flex;
}

.flex_center {
  justify-content: center;
  align-items: center;
}

.justify-content{
  &--space-between {
    justify-content: space-between;
  }
}

.align-items{
  &--center{
    align-items: center;
  }
}

.hide_mobile {
  @media #{$large-mobile} {
    display: none !important;
  }
}

.hide_desktop {
    display: none !important;
}

.show_mobile {
  @media #{$large-mobile} {
    display: block !important;
  }
}

.show_desktop {
    display: block;
}

.width-100 {
  width: 100%;
}

.img-article-home {
    margin-right: 4rem;
    object-fit: contain;
    img {
      border-radius: 1.5rem;
      width: 100%;
    }
    @media #{$large-mobile} {
          margin-right: 0rem;
    }
}

.img-article-articles {
    object-fit: contain;
    img {
      border-radius: 1.5rem;
      width: 100%;
    }
    video {
      width: 100%;
    }
    @media #{$large-mobile} {
          margin-right: 0rem;
    }
}


.video-article-articles {
    object-fit: contain;
    video {
      border-radius: 1.5rem;
      width: 100%;
    }
    @media #{$large-mobile} {
          margin-right: 0rem;
    }
}

.img-article-inside-text {
    object-fit: contain;
    img {
      border-radius: 1.5rem;
      width: 50%;
      @media #{$large-mobile} {
            width: 100%;
      }
    }
    @media #{$large-mobile} {
          margin-right: 0rem;
    }
}

.img-article-home-2 {
    margin-left: 4rem;
    object-fit: contain;
    img {
      border-radius: 1.5rem;
    }
    video {
      border-radius: 1.5rem;
    }
    @media #{$large-mobile} {
          margin-left: 0rem;
    }
}

.img-article-article {
    margin-left: 4rem;
    width: 65%;
    object-fit: contain;
    img {
      border-radius: 1.5rem;
      width: 100%;
    }
    video {
      border-radius: 1.5rem;
      width: 100%;
    }
    @media #{$large-mobile} {
          margin-left: 0rem;
          width: 100%;
    }
}

.articolo-preview{
  margin-right: 2rem;
      img {
        border-radius: 1.5rem;
      }
  @media #{$large-mobile} {
        margin-left: 0rem;
        width: 100%;
  }
}

.articolo-preview-2{
  width: 30%;
  @media #{$large-mobile} {
        margin-left: 0rem;
        width: 100%;
  }
}

.video_home_feed {
  object-fit: cover;
  width: 37rem;
  height: 27rem;
  border-radius: 1.5rem;
  @media #{$small-desktop} {
    width: 30rem;
    height: 20rem;
  }
}

.articoli-suggeriti{
  display: flex;
  @media #{$large-mobile} {
      flex-direction: column;
  }
}

.fade {
  transition: opacity 0.4s ease-in;
}

.fadeOut { opacity: 0; }
.fadeIn { opacity: 1; }



.hamburger{
  background-color: none;
  color: black;
}

.mobile-container {
  max-width: 480px;
  margin: auto;
  background-color: #555;
  height: 500px;
  color: white;
  border-radius: 10px;
}

.topnav {
  overflow: hidden;
  position: relative;
}
/*this is the dropdown
  my header-hamburger-menu
  */
#myLinks{
  justify-content: center;
  align-items: center;
}

.topnav #myLinks {
    height: 100vw;
  transition: max-height 0.3s ease-out;
}

.topnav a {
  padding: 14px 16px;
  text-decoration: none;
  display: block;
}

/* this is the hamburger button*/
.topnav a.hamburger {
  background: none;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
  /* this did not work for me */
.topnav a.hamburger:hover + .topnav #myLinks {
  height: 100vw;

}

.topnav a:hover {
  color: black;
}

.active {
  background-color: #4CAF50;
  color: white;
}


.scopri-articoli-btn {
  font-size: 2rem;
  padding: 0.5rem 5rem 0.5rem 5rem;
  border-radius: 5rem;
  border: 2px solid black;
  @media #{$large-mobile} {
    font-size: 1.5rem;
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;
    }
}

.arrow-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:-10rem;
  @media #{$large-mobile} {
    margin-top:0rem;
    img {
      width: 4rem;
    }
  }
}

.card-1 {
  border: 2px solid #1735fc;
  border-radius: 1.25rem;
  padding: 2.5rem 3rem 2.5rem 3rem;
  margin-right:1rem;
  width:30%;
  @media #{$large-mobile} {
        width:100%;
        margin-right:0rem;
        margin-bottom: 1rem;
        padding: 1rem;
        h3 {
          margin: 0;
        }

  }
}

.card-2 {
  border: 2px solid #1735fc;
  border-radius: 1.25rem;
  padding: 2.5rem 3rem 2.5rem 3rem;
  width:30%;
  @media #{$large-mobile} {
      width:100%;
      padding: 1rem;
      h3 {
        margin: 0;
      }
  }
}

.no-magin{
  margin: 0 !important;
}

.text-centered {
  text-align: center;
}

.ul-psy{
  width:0.6rem;
  margin: 0rem 0.6rem 0rem 0.6rem;
}

.quote{
  width:50%;
  @media #{$large-mobile} {
        width:100%;
  }
}

.autore-div{
  background-color: #c4c4c4;
  width: 100%;
  padding: 4rem 4rem 6rem 4rem;
  display: flex;
  border-radius: 1rem;
  margin: 1rem 0 5rem 0;
  @media #{$large-mobile} {
        padding: 3rem 3rem 4rem 3rem;
        flex-direction: column;
  }
}

.autore-bio{
  color: #767676;
}

.autore-nome{
  width: 40%;
}



#menu-mobile a{
    text-decoration: none;
    color: black;
    display: block;
    transition: 0.3s;
}

.btn-open{
  cursor:pointer;
}

.icon-hamburger {
  width: 4.5rem;
  z-index: 999999;
}

.icon-hamburger span {
  height: 3px;
  width: 45px;
  background: black;
  position: relative;
  display: block;
  margin-top: 0.5rem;
  transition: all 0.3s cubic-bezier(.215, .61, .355, 1);
}

.menu-open .icon-hamburger span:nth-child(1){transform: rotate(45deg) translateY(6px);}
.menu-open .icon-hamburger span:nth-child(2){transform: rotate(-45deg) translateY(-9px);}


.menu-mobile{
    height: 0vh;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255, 1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
    box-shadow: 0 0 40px 0 rgba(0,0,0,0.16);
    background: rgba(255,255,255,1);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
}

.menu-open .menu-mobile{
    height: 100vh;
    padding: 5%;
}



.overlay-content {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.closebtn {
  position: absolute;
  top: 0;
  right:0;
}

.button-x-text-header {
  font-size: 3.5rem;
  margin:2rem 2rem 1.5rem 1.5rem;
}

.projects_flex{
  display: flex;
  @media #{$large-mobile} {
        flex-direction: column;
  }
}

.element-item-project{
  margin: 1rem;
  @media #{$large-mobile} {
        margin: 0rem 0rem 5rem 0rem;
  }
}

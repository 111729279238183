/* ---- .element-item ---- */

.element-item {
  position: relative;
  display: flex;
  margin: 2rem;
  @media #{$small-desktop} {
    margin: 1rem;
  }
  @media #{$large-mobile} {
    margin: 2rem;
  }
}
